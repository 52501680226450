import React from "react";
import { ThemeProvider } from "styled-components";
import Theme from "./src/themes/theme";
import Layout from "./src/components/layout";
import { GlobalStyle } from "./src/themes/global-style";


export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    // Tawk.to integration
    const Tawk_API = window.Tawk_API || {};
    const Tawk_LoadStart = new Date();
    (function() {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66d0b1f250c10f7a00a1c86b/1i6fje7f2';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();

    // Google Analytics integration
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-16549668597');
    gtag('config', 'G-5QJWXSQG25');
  }
};

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    <Layout>{element}</Layout>
  </ThemeProvider>
);
