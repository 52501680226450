import { createGlobalStyle } from "styled-components"
import theme from "./theme"

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Sansita One';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url(/fonts/SansitaOne.ttf) format("woff2");
  }
  *, *:before, *:after {
    margin:0;
    padding : 0;
    text-decoration: none;
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${theme.fonts.main};
    font-size: 16px;
    ${"" /* color: ${props => props.theme.colors.dark1}; */}
    color: ${theme.colors.dark1};
  }

  body {
    line-height: 1.2;
    letter-spacing: 0.8px;
    ${"" /* background-color: ${props => props.theme.colors.light3}; */}
    color: ${theme.colors.light3};
  }

  span, p, a {
    font-family: ${theme.fonts.secondary};
    /* text-transform: capitalize; */
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.tertiary};
    font-style: normal;
    text-transform: capitalize;
  }

  .wave{
    ${"" /* margin-top:-150px; */}
    opacity:1;
    width:100%;
    position:absolute;
    bottom:-105px;
    z-index:2;
    

    @media screen and (max-width: 1100px){
    bottom:-80px;
  }
    @media screen and (max-width: 983px){
     display:none;
  }
  }

  .flex-row {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .gap-1 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 2rem;
  }
  .gap-3 {
    gap: 3rem;
  }
  .gap-4 {
    gap: 4rem;
  }
  .gap-half {
    gap: .5rem;
  }

  .grid {
    display: grid;
  }

  .ai-c {
    align-items: center;
  }
  .ai-fs {
    align-items: flex-start;
  }
  .ai-fe {
    align-items: flex-end;
  }
  .jc-sa {
    justify-content: space-around;
  }
  .jc-c {
    justify-content: center;
  }
  .jc-sb {
    justify-content: space-between;
  }

  .waveTwo{
    ${"" /* margin-top:-150px; */}
    opacity:1;
    width:100vw;
    position:absolute;
    bottom:-100px;
    z-index:2;
    ${"" /* right:-13px; */}

    @media screen and (max-width: 1100px){
    bottom:-75px;
  }
    @media screen and (max-width: 983px){
     display:none;
  }
  }
  .header-class .headerContainer{
    /* border-bottom: 1px solid lightgrey;
    box-shadow: 0 4px 6px -6px #222; */
  }
  .header-class .headerLink{
    color: #3F3D56;
    &:hover {
      color: ${theme.colors.accentSkyBlue};
    }
    &.active {
      color: ${theme.colors.accentSkyBlue};
    }
    @media screen and (max-width: 768px){
      color: ${theme.colors.light3};
    }
  }

  .header-class .dropdown-headerLink{
    ${"" /* color: ${theme.colors.light3}; */}
    &:hover {
      color: ${theme.colors.light3};
    }
    &.active {
      color: ${theme.colors.light3};
      background: ${theme.colors.accentSkyBlue};
    }
    /* @media screen and (max-width: 768px){
      color: ${theme.colors.light3};
    } */
  }

  .active {
    color: ${theme.colors.accentSkyBlue};
  }

  .active-dropdown {
    color: ${theme.colors.light3}
  }
`
